<template>
  <div>
    <el-upload
      class="upload-demo"
      drag
      :action="action"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="onRemove"
      :data="data"
      :limit="limit"
      :accept="accept"
      :multiple="multiple && limit > 1"
      :http-request="upload"
    >
      <img
        :src="img_url"
        style="width: 100%"
        alt=""
        v-if="url && (type === 'img' || type === 'image') && !multiple"
      />
      <video
        :src="img_url"
        style="width: 100%; height: 180px"
        alt=""
        v-else-if="url && type === 'video'"
      />
      <template v-else>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text" v-if="!uploadText">
          拖动或<em>浏览</em>添加{{
            type === "img" || type === "image" ? "图片" : "视频"
          }}
        </div>
        <div class="el-upload__text" v-else>
          <div>拖动或<em>浏览</em>添加{{ uploadText }}</div>
          <div class="ruleStrBox">{{ ruleStr }}</div>
        </div>
      </template>
    </el-upload>
    <el-link
      v-if="idHelpFile"
      type="primary"
      href="/ID上传步骤说明.pdf"
      target="_blank"
      >ID上传帮助文档</el-link
    >
  </div>
</template>

<script>
import { getSignature, getImgUrl } from "@/api/upload";
import axios from 'axios'
import { join } from "path";
import { async } from "q";
const stream = require("stream");
const Minio = require("minio");
export default {
  data() {
    return {
      data: {
        policy: "",
        signature: "",
      },
      fileList: [],
      action: "http://v0.api.upyun.com/hippo-file",
      source: "aliyun",
      dir: "",
      base_url: "",
      file_name: "",
      img_url: "",
      minioObj: {
        // endPoint: res.base_url, //"服务ip",
        // port: 80, //"端口",
        // useSSL: false, //false, //true代表使用HTTPS
        // accessKey: res.accessid, //"账号",
        // secretKey: res.signature, // "密码",
      },
      minioBucket: "autofort",
    };
  },
  props: {
    type: {
      type: String,
      default: "img",
    },
    url: {
      type: String,
      default: "",
    },
    propBeforeUpload: null,
    uploadText: {
      type: String,
      default: "",
    },
    ruleStr: {
      // 规则文案
      type: String,
      default: "",
    },
    before: {
      // 上传前处理
      type: Function,
      default: null,
    },
    idHelpFile: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    isMoments: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    url(newVal) {
      if (!newVal) {
        this.fileList = [];
      }
    },
  },
  methods: {
    setFileList(obj) {
      this.fileList = [
        {
          name: obj.name,
          url: obj.url,
        },
      ];
      this.img_url = obj.img_url;
    },
    onRemove(file, fileList) {
      this.$emit("remove", file, fileList);
    },
    async upload({onSuccess,onError,file}) {
      const fd = new FormData();
      let arr = file.name.split('.')
      let newFileName = ''
      if (arr.length > 1) {
        const fileType = arr[arr.length - 1]
        arr.pop()
        newFileName = arr.join('.') +  '(' + new Date().getTime() +').'+ fileType
      } else {
        newFileName = file.name + '(' + new Date().getTime() +')'
      }
      const copyFile = new File([file], newFileName, {type: file.type})
      if (this.type === "img") {
        const maxWidth = 1440, maxHeight = 1080
        const size = await this.validRatio(file)
        if ((size.width > maxWidth || size.height > maxHeight) && this.isMoments) {
          this.$message.error('文件分辨率不能超过1440*1080')
          onError('文件分辨率不能超过1440*1080')
          return
        }
      }
      for(const key in this.data) {
        fd.append(key, this.data[key])
      }
      fd.append('file', copyFile)
      console.log(fd)
      axios.post(this.action, fd, {
        baseURL: '',
        'Content-type' : 'multipart/form-data',
        timeout: 60 * 60 * 1000
      }).then((res) => {
        onSuccess(res, copyFile)
        this.onSuccess(res, copyFile)
      }).catch((err) => {
        onError(err)
      });
    },
    // async compressImg(file, size, scale) {
    //   const result = await imageConversion.compressAccurately(file, {
    //     quality: scale,
    //     type: file.type,
    //     width: size.width,
    //     height: size.height,
    //     scale: scale,
    //   })
    //   return new File([result], file.name, { type: file.type });
    // },
    validRatio(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.readyState == 2) {
            const img = new Image();
            img.src = reader.result;
            img.onload = (event) => {
              resolve({
                width: event.target.width,
                height: event.target.height
              });
            };
          }
        };
      });
    },
    async beforeUpload(file) {
      
      if (this.before) {
        if (!this.before()) {
          return new Promise.reject();
        }
      }

      const res = await getSignature();

      this.source = res.source;
      // 又拍云
      if (res.source === "youpai") {
        this.action = "http://v0.api.upyun.com/hippo-file";
        this.data.policy = res.policy;
        this.data.signature = res.signature;
      }
      // 阿里云
      else if (res.source === "aliyun") {
        this.dir = res.dir;
        this.action = res.host;
        this.base_url = res.base_url;
        this.fileName = this.data.policy = res.policy;
        this.data.OSSAccessKeyId = res.accessid;
        this.data.success_action_status = 200;
        this.data.signature = res.signature;
        this.data.callback = res.callback;
        this.data.key = res.dir + "${filename}";
        this.data.expire = res.expire;
      } else if (res.source === "minio") {
        this.action = `${res.endPoint}:${res.port}/minio/${res.Bucket}/${res.dir}`; // 为了不报错
        const endPoint = res.endPoint.substring(res.endPoint.lastIndexOf("/") + 1);
        this.minioObj = {
          endPoint: endPoint, //"服务ip"
          port: parseInt(res.port), //"端口"
          useSSL: res.endPoint.startsWith("https"), //true代表使用HTTPS
          use_path_style_endpoint: true,
          accessKey: res.accessKey, //"账号"
          secretKey: res.secretKey, // "密码"
        };
        this.minioBucket = res.Bucket;
        this.base_url = `${res.endPoint}:${res.port}/${res.Bucket}/`;
        this.dir = res.dir;
      }

      if (this.propBeforeUpload) {
        return this.propBeforeUpload(file);
      }

      // 图片：2mb，  JPG PNG
      // 视频：10mb， mp4
      var isType, isLt, lt;
      if (this.type === "img") {
        isType = ["image/png", "image/jpeg"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 2;
        lt = 2;
        if (file.size / 1024 < 5) {
          this.$message.error(`上传图片不能小于5KB!`);
          return new Promise.reject();
        }
      }
      if (this.type === "voice") {
        isType = (["audio/amr"].indexOf(file.type) > -1) || file.name.endsWith("amr");
        isLt = file.size / 1024 / 1024 < 2;
        lt = 2;
      }
      if (this.type === "video") {
        isType = ["video/mp4"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }
      if (this.type === "csvFile") {
        isType =
          ["application/vnd.ms-excel", "text/csv"].indexOf(file.type) > -1;
        isLt = file.size / 1024 / 1024 < 10;
        lt = 10;
      }

      if (!isType) {
        this.$message.error(`文件格式不正确 ${file.type}`);
      }
      if (!isLt) {
        this.$message.error(`上传文件大小不能超过 ${lt}MB!`);
      }
      // 如果不符合条件
      if (!isType || !isLt) {
        return new Promise.reject();
      }
      return true;
    },
    onSuccess(response, file) {
      console.log(response)
      if (this.source === "aliyun") {
        this.file_name = file.name;
        getImgUrl(this.dir + this.file_name, this.type === "img" && true).then((res) => {
          this.img_url = res.signUrl;
          this.$emit("file", {
            // url: encodeURI(this.base_url + this.dir + this.file_name),
            url: res.signUrl,
            name: file.name,
            fileId: file.uid || file.name,
            img_url: this.img_url,
          });
        });
      } else if (this.source === "youpai") {
        this.img_url = encodeURI(
          "https://hippo-file.hemacrm.cn" + response.url
        );
        this.$emit("file", {
          url: encodeURI("https://hippo-file.hemacrm.cn" + response.url),
          name: file.name,
          fileId: response.sign,
          img_url: this.img_url,
        });
      } else if (this.source === "minio") {
        //储存在minio
        this.uploadMinIo(file.raw);
      }
    },
    uploadMinIo(fileObj) {
      let vm = this;
      if (fileObj) {
        let file = fileObj;
        //选择
        //获取文件类型及大小
        const fileName = file.name;
        const mineType = file.type;
        const fileSize = file.size;

        //参数
        const metadata = {
          "content-type": mineType,
          "content-length": fileSize,
        };
        const minioClient = new Minio.Client({
          ...this.minioObj,
        });
        //判断储存桶是否存在
        minioClient.bucketExists(vm.minioBucket, function (err) {
          if (err) {
            if (err.code == "NoSuchBucket") return console.log("不存在");
            return console.log(err);
          }
          //存在
          console.log("存在储存桶");
          //准备上传
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            const dataurl = e.target.result;
            //base64转blob
            const blob = vm.toBlob(dataurl);
            //blob转arrayBuffer
            let reader2 = new FileReader();
            reader2.readAsArrayBuffer(blob);

            reader2.onload = function (ex) {
              //定义流
              let bufferStream = new stream.PassThrough();
              //将buffer写入
              bufferStream.end(new Buffer(ex.target.result));
              //上传
              minioClient.putObject(
                vm.minioBucket,
                vm.dir + fileName,
                bufferStream,
                fileSize,
                metadata,
                function (err, etag) {
                  if (err == null) {
                    minioClient.presignedGetObject(
                      vm.minioBucket,
                      vm.dir + fileName,
                      24 * 60 * 60,
                      function (err, presignedUrl) {
                        if (err) return console.log(err);
                        //输出url 可以下载的链接
                        console.log(presignedUrl);
                        console.log("请求后端");
                        getImgUrl(vm.base_url + vm.dir + fileName, vm.type === "img" && true).then((res) => {
                          vm.img_url = res.signUrl;
                          vm.$emit("file", {
                            // url: encodeURI(vm.base_url + vm.dir + fileName),
                            url: res.signUrl,
                            name: fileName,
                            fileId: file.uid || file.name,
                            img_url: res.signUrl,
                          });
                        });
                      }
                    );
                  } else {
                    console.log(err, etag);
                  }
                }
              );
            };
          };
        });
      }
    },
    //toBlob方法 base64转blob
    toBlob(base64Data) {
      let byteString = base64Data;
      if (base64Data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(base64Data.split(",")[1]); // base64 解码
      } else {
        byteString = unescape(base64Data.split(",")[1]);
      }
      // 获取文件类型
      let mimeString = base64Data.split(";")[0].split(":")[1]; // mime类型

      // ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
      // let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      // let uintArr = new Uint8Array(arrayBuffer) // 创建视图

      let uintArr = new Uint8Array(byteString.length); // 创建视图

      for (let i = 0; i < byteString.length; i++) {
        uintArr[i] = byteString.charCodeAt(i);
      }
      // 生成blob
      const blob = new Blob([uintArr], {
        type: mimeString,
      });
      // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
      return blob;
    },
  },
};
</script>

<style>
.el-upload-dragger {
  min-height: 180px;
  height: auto;
  width: 400px;
}
.ruleStrBox {
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  padding: 5px 15px 10px 15px;
}
.el-upload-list {
  width: 400px;
}
</style>
