<template>
  <div class="cond-item-wrap" :class="{ empty: isEmpty }">
    <div class="title">{{ title }}</div>
    <div class="sub-title" v-if="subTitle">{{ subTitle }}</div>
    <div class="items">
      <span class="placeholder" v-if="showPlaceholder">{{ placeholder }}</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: () => "所有条件",
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    isEmpty: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
.cond-item-wrap {
  margin-bottom: 40px;
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }
  .sub-title {
    margin-bottom: 13px;
    font-size: 12px;
    line-height: 19px;
  }
  .items {
    padding: 20px 22px 20px 16px;
    border: 1px solid #dddddf;
    border-radius: 6px;
    .placeholder {
      display: none;
      margin-left: 16px;
      margin-right: 50px;
      font-size: 16px;
      line-height: 25px;
    }
  }
}
.empty {
  .items {
    display: flex;
    align-items: center;
    height: 80px;
    .placeholder {
      display: inline;
    }
  }
}
</style>
